import React from 'react';
import { NoteFileProcessor } from '../../note-file-processor/NoteFileProcessor';
import { Form } from 'react-bootstrap';
import { getFileProcessor } from '../../note-file-processor/NoteFileProcessorFactory';

interface NoteFileSubmissionProps {
    on_submission: (file_processor: NoteFileProcessor) => void;
}

const NoteFileSubmission = ({ on_submission }: NoteFileSubmissionProps) => {
    const on_change = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files === null || files.length === 0)
            throw new Error("File input is invalid or empty.");

        on_submission(getFileProcessor(files[0]));
    }

    return (
        <Form.Control 
            type="file" 
            placeholder="Choose a file of notes..." 
            onChange={on_change} />

    );
}

export default NoteFileSubmission;