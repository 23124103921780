import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import ReviewDisplay from './ReviewDisplay';
import { Review } from '../../noteworthy-api/objects/Review';
import { getClient } from '../../noteworthy-api/ClientFactory';
import PromiseLoading from '../../components/PromiseLoading';
import { Button, Container, Row } from 'react-bootstrap';
import { ExcelReviewsWriter } from '../../review-file-writer/ExcelReviewsWriter';
import FeedbackList from './FeedbackList';
import PoweredByOpenai from '../../components/PoweredByOpenai';

function downloadReviews(reviews: Review[]): void {
    let xlsx_writer = new ExcelReviewsWriter();
    xlsx_writer.downloadReviews(reviews);
}

/**
 * Page for displaying the reviews of notes in a request.
 * 
 * Gets which request by the query of the URL.
 * Ex: /review?request=1234
 */
const ReviewsPage: React.FC = () => {
    const [search_params, _] = useSearchParams();
    const request_param = search_params.get("request");
    
    if (request_param === null)
        throw new Error("No request ID was given.");

    const request_id = Number.parseInt(request_param);
    const [reviews, set_reviews] = useState<Review[] | null>(null);
    const noteworthy = getClient();

    if (request_param === null) {
        throw Error("The required query for request is not given.");
    }

    if (reviews === null) {
        return <PromiseLoading 
            promise={noteworthy.getRequestReviews(request_id)}
            on_resolve={reviews => set_reviews(reviews)}/>
    }

    return (
        <>
        <Container>
            <Row>
                <Button 
                 onClick={() => downloadReviews(reviews)}>
                    Download as Excel
                </Button>
            </Row>
            <Row>
                <ReviewDisplay reviews={reviews} />
                <FeedbackList reviews={reviews} />
            </Row>
        </Container>
        <PoweredByOpenai width="15vw" height="auto"/>
        </>
    );
}

export default ReviewsPage;