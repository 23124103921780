import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";

interface PromiseLoadingProps<T> {
    children?: React.ReactNode;
    promise?: Promise<T>;
    on_resolve?: (result: T) => void;
    on_error?: (error: Error) => void;
}

function PromiseLoading<T>({
        children, 
        promise, 
        on_resolve, 
        on_error}: PromiseLoadingProps<T>) {
    const [is_resolved, set_is_resolved] = useState(false);
    useEffect(() => {
        if (!is_resolved && promise !== undefined) {
            promise
            .then(on_resolve)
            .catch(on_error)
            .finally(() => set_is_resolved(true));
        }
    }, [on_resolve, on_error]);

    if (!is_resolved) {
        return (
            <div style={{display: "flex", justifyContent: "center"}}>
                <Spinner />
            </div>
        );
    }

    return (<>{children}</>);
}

export default PromiseLoading;