import React from 'react';
import { Form, ListGroup } from 'react-bootstrap';
import { useClickAway } from '@uidotdev/usehooks';

type FilterPopUpListProps = {
    /** Items that show up in the list. */
    search_items: string[];

    /** The filter applied on shown items. Only items which contain this
     * string will be shown.
     */
    filter?: string;

    /** Callback for when an item is clicked. */
    on_selection?: (item: string) => void;
}

/** A popup list which can have a filter applied to it's items. */
const FilterPopUpList = ({
        search_items,
        filter,
        on_selection}: FilterPopUpListProps) => {
    return (
        <ListGroup style={{
            position: "absolute",
            borderColor: "black", 
            borderStyle: "solid", 
            borderWidth: "1px",
            maxHeight: "25vh",
            overflowY: "auto",
            zIndex: 1
        }}>
            {search_items
            .filter(item => item.startsWith(filter ?? ""))
            .map(item =>
                <ListGroup.Item
                    action 
                    onClick={() => {on_selection && on_selection(item)}}
                >
                    {item}
                </ListGroup.Item>)}
        </ListGroup>
    );
}

export interface FilterSearchProps {
    /** Items to search for. */
    search_items: string[];

    /** A placeholder for the filter input. */
    placeholder?: string;

    /** Callback for when an item is clicked. */
    on_selection?: (item: string) => void;

    /** Style for the component. */
    style?: React.CSSProperties;
}

const FilterSearch = ({
        search_items,
        placeholder, 
        on_selection,
        style}: FilterSearchProps) => {
    const [filter, set_filter] = React.useState("");
    const [focused, set_focused] = React.useState(false);
    const clickaway_ref = useClickAway(() => set_focused(false));
    
    return (
        <div ref={clickaway_ref as React.RefObject<HTMLDivElement>}>
            <Form.Control
                placeholder={placeholder}
                onChange={(e) => set_filter(e.target.value)}
                style={style}
                onFocus={() => set_focused(true)}
            />
                {focused && 
                    <FilterPopUpList 
                        filter={filter} 
                        search_items={search_items} 
                        on_selection={on_selection}
                    />}
        </div>
    );
}

export default FilterSearch;