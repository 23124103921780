import React from "react";
import { Alert, AlertProps } from "react-bootstrap";

interface TimedAlertProps extends AlertProps {
    seconds?: number;
    children?: React.ReactNode;
}

/* Alert which closes after a given number of seconds */
const TimedAlert = ({ seconds, children, onClose, ...props }: TimedAlertProps) => {
    const [shown, set_shown] = React.useState(true);

    const on_times_up = () => {
        if (onClose !== undefined) 
            onClose(0, 0);

        set_shown(false);
    }

    React.useEffect(() => {
        if (seconds !== undefined)
            setTimeout(on_times_up, seconds * 1000);
    });

    return <Alert style={{position: "fixed", zIndex: 100, width: "33vw"}} show={shown} onClose={onClose} {...props}>
        {children}
    </Alert>
}

export default TimedAlert;