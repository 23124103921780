import React from 'react';
import { Button, Form, Stack } from 'react-bootstrap';
import { NotificationType, NotifierContext } from '../../components/NotificationProvider';
import { useNavigate } from 'react-router-dom';
import UserAPI from '../../UserAPI';

enum SignupStatus {
    WAITING_EMAIL,
    PENDING_SIGNUP,
    SENDING_CONFIRMATION,
    WAITING_CONFIRMATION_INFO,
    PENDING_CONFIRMATION,
    CONFIRMING,
    SIGNED_UP
}

const SignupPage = () => {
    const [status, set_status] = React.useState(SignupStatus.WAITING_EMAIL);
    const notifier = React.useContext(NotifierContext);
    const navigate = useNavigate();
    const user_api = React.useMemo(() => new UserAPI(), []);
    const email_ref = React.createRef<HTMLInputElement>();
    const password_ref = React.createRef<HTMLInputElement>();
    const confirmation_ref = React.createRef<HTMLInputElement>();

    const continue_handler = () => {
        switch (status) {
            case SignupStatus.WAITING_EMAIL:
                set_status(SignupStatus.PENDING_SIGNUP);
                return;
            case SignupStatus.WAITING_CONFIRMATION_INFO:
                set_status(SignupStatus.PENDING_CONFIRMATION);
                return;
            case SignupStatus.SIGNED_UP:
                navigate("/");
                return;
        }
    }

    React.useEffect(() => {
        if (status === SignupStatus.PENDING_SIGNUP) {
            if (email_ref.current === null) return;
            
            const email = email_ref.current.value;
            
            user_api.requestValidation(email)
            .then(response => set_status(SignupStatus.WAITING_CONFIRMATION_INFO))
            .catch(e => {
                notifier(NotificationType.ERROR, e.message);
                set_status(SignupStatus.WAITING_EMAIL);
            });

            set_status(SignupStatus.SENDING_CONFIRMATION);
        }

        if (status === SignupStatus.PENDING_CONFIRMATION) {
            if (
                password_ref.current === null 
                || confirmation_ref.current === null
                || email_ref.current === null
            )
                return;

            const email = email_ref.current.value;
            const password = password_ref.current.value;
            const confirmation = confirmation_ref.current.value;

            user_api.validateEmail(email, password, confirmation)
            .then(success => {
                if (success) {
                    set_status(SignupStatus.SIGNED_UP);
                } else {
                    set_status(SignupStatus.WAITING_CONFIRMATION_INFO);
                    notifier(NotificationType.ERROR, "Your email couldn't be validated. Check that the code is correct and try again.");
                }
            })
            .catch(e => {
                notifier(NotificationType.ERROR, e.message);
                set_status(SignupStatus.WAITING_CONFIRMATION_INFO);
            });
            
            set_status(SignupStatus.CONFIRMING);
        }
    }, [status]);

    return (
        <Stack 
            gap={1} 
            direction="vertical" 
            className="mx-auto" 
            style={{width: "33vw"}}
        >
            {status !== SignupStatus.SIGNED_UP ?
                <>
                <label>Enter the email to use for your account:</label>
                <Form.Control
                    ref={email_ref}
                    placeholder="Email"
                    type="email"
                    disabled={status >= SignupStatus.PENDING_SIGNUP}
                />
                { status >= SignupStatus.WAITING_CONFIRMATION_INFO && 
                    <>
                    <label>Enter your new password and the confirmation code sent to the given email:</label>
                    <Form.Control
                        ref={password_ref}
                        placeholder="Password"
                        type="text"
                        disabled={status >= SignupStatus.PENDING_CONFIRMATION}
                    />
                    <Form.Control
                        ref={confirmation_ref}
                        placeholder="Confirmation Code"
                        type="text"
                        disabled={status >= SignupStatus.PENDING_CONFIRMATION}
                    />
                    </>
                }
                </>
                : <><h2>You are now signed up!</h2></>
            }
            
            <Button variant="primary" onClick={continue_handler}>
                {status === SignupStatus.SIGNED_UP
                    ? "Back to Login"
                    : "Continue"
                }
            </Button>
        </Stack>
    );
}

export default SignupPage;