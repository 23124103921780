import { Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";

/** The navbar at the top of the website. */
const NoteworthyNavbar = () => {
    return (
        <Navbar sticky="top" className="bg-body-secondary">
            <Navbar.Brand>Noteworthy</Navbar.Brand>
            <Nav>
                <Nav.Link as={Link} to="/requests">Requests</Nav.Link>
            </Nav>
        </Navbar>
    );
}

export default NoteworthyNavbar;