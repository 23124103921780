import React, { useState } from 'react';
import FilterSearch, { FilterSearchProps } from './FilterSearch';
import { Badge, InputGroup, Stack } from 'react-bootstrap';

/**
 * Props for the SelectItems component.
 * 
 * @property header Header for the selected items.
 * @property max_chosen Max amount of items which can be selected.
 * @property on_change Function to be called when the selection
 *      changes. The items which are selected are passed as a parameter.
 */
interface SelectItemsProps extends FilterSearchProps {
    header?: string;
    max_selected?: number;
    on_change?: (new_chosen: string[]) => void;
}

/**
 * Checksi if the given item is in the given list.
 * @param item Item to search for.
 * @param list List to search in.
 * @returns True if the item is in the list, false otherwise.
 */
function contains(item: string, list: string[]): boolean {
    return list.find(search_item => search_item === item) != undefined;
}

/**
 * Updates the list of selected items based on the item selected.
 * @param selected_item The item selected.
 * @param selected The currently selected items.
 * @param set_selected Setter for the selected items
 * @param max The maximum amount of items which can be selected.
 * @returns The updated list.
 */
function updateSelectedList(
    selected_item: string,
    selected: string[], 
    set_selected: React.Dispatch<React.SetStateAction<string[]>>,
    max?: number
): string[] {
    let result: string[] = [];

    if (contains(selected_item, selected)) {
        // If the item is selected, remove it.
        result = selected.filter(item => item !== selected_item);
        set_selected(result);
    } else if (!max || selected.length < max ) {
        // If the amount of items selected isn't maxed out add the item.
        result = selected.concat([selected_item]);
        set_selected(result);
    }

    return result;
}

/**
 * Component for allowing a user to select items from a list and tracking
 * which are selected.
 */
const SelectItems = ({
        header, 
        max_selected, 
        on_change,
        on_selection,
        search_items,
        ...other}: SelectItemsProps) => {
    let max = max_selected ?? search_items.length;
    const [selected, set_selected] = useState<string[]>([]);

    // Wrapper for when an item is selected.
    const on_selection_wrapper = (item: string) => {
        const new_list = updateSelectedList(
            item, 
            selected,
            set_selected, 
            max
        );

        if (on_selection)
            on_selection(item);

        if (on_change)
            on_change(new_list);
    }

    return (
        <div>
            <InputGroup>
                <InputGroup.Text>{header}</InputGroup.Text>
                <Stack 
                    direction="horizontal"
                    style={{
                        borderRadius: "0px 5px 5px 0px",
                        backgroundColor: "lightgray",
                    }}
                >
                    {selected.map(item => <Badge pill>{item}</Badge>)}
                </Stack>
            </InputGroup>
            <FilterSearch 
                on_selection={on_selection_wrapper} 
                search_items={search_items}
                {...other}
            />
        </div>
    );
}

export default SelectItems;