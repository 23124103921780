import React from 'react';
import { Alert } from 'react-bootstrap';
import TimedAlert from './TimedAlert';

export enum NotificationType {
    NOTICE = "notice",
    ERROR = "error",
    SUCCESS = "success"
}

export type Notification = {
    type: NotificationType;
    message: string;
}

export enum NotificationActionType {
    SEND = "send",
    DELETE = "delete"
}

interface NotificationAction {
    type: NotificationActionType;
    notification: Notification;
}

function removeNotification(
    notifications: Notification[], 
    removed: Notification
): Notification[] {
    return notifications.filter(
        notification => notification !== removed
    );
}

function reduceNotificationAction(
    state: Notification[], 
    action: NotificationAction
): Notification[] {
    switch (action.type) {
        case NotificationActionType.SEND:
            return state.concat(action.notification);
        case NotificationActionType.DELETE:
            return removeNotification(state, action.notification);
    }
}

export const NotifierContext = React.createContext(
    {} as (type: NotificationType, message: string) => void
);

const notification_type_to_alert = {
    "notice": "primary",
    "error": "danger",
    "success": "success"
}

const notification_close_time = {
    "notice": 10,
    "success": 5,
    "error": undefined
}

function formatHeader(header: string): string {
    return header.charAt(0).toUpperCase() + header.slice(1);
}

function formatNotifications(
    notifications: Notification[], 
    on_close_factory: (n: Notification) => (() => void)
): React.ReactNode[] {
    return notifications.map((notification, index) =>
        <TimedAlert
            seconds={notification_close_time[notification.type]}
            variant={notification_type_to_alert[notification.type]}
            onClose={on_close_factory(notification)}
            key={index}
            dismissible
        >
            <Alert.Heading>{formatHeader(notification.type)}</Alert.Heading>
            <p>{notification.message}</p>
        </TimedAlert>
    );
}

const NotificationProvider = ({ children }: React.PropsWithChildren) => {
    const [notifications, dispatch] = React.useReducer(reduceNotificationAction, []);

    const notifier = (type: NotificationType, message: string) => {
        dispatch({
            type: NotificationActionType.SEND,
            notification: { type, message }
        });
    }

    return (
        <NotifierContext.Provider value={notifier}>
            {
                formatNotifications(
                    notifications,
                    n => { 
                        return () => dispatch(
                            { type: NotificationActionType.DELETE, notification: n }
                        );
                    }
                )
            }
            {children}
        </NotifierContext.Provider>
    );
}

export default NotificationProvider;