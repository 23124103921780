import sha256 from "crypto-js/sha256";

const API_URL = "https://yn8r0w4ppi.execute-api.us-east-1.amazonaws.com"

export default class UserAPI {
    constructor() {}

    async requestValidation(email: string): Promise<Response> {
        return fetch(API_URL + "/request-verification", {
            method: "POST",
            body: JSON.stringify({
                email: email})});
    }

    async validateEmail(
        email: string, 
        password: string, 
        code: string
    ): Promise<boolean> {
        let pass_hash = sha256(password).toString();
        return fetch(API_URL + "/verify-email", {
            method: "POST",
            body: JSON.stringify({
                email: email,
                password: pass_hash,
                code: code})})
        .then(response => response.json())
        .then(body => body["is_verified"]);
    }

    async login(email: string, password: string): Promise<boolean> {
        let pass_hash = sha256(password).toString();
        return fetch(API_URL + "/login", {
            method: "POST",
            body: JSON.stringify({
                email: email,
                password: pass_hash})})
        .then(response => response.json())
        .then(body => body["logged_in"]);
    }
}