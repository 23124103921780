import React from 'react';
import { Stack, StackProps } from 'react-bootstrap';
import RequestStatus from './RequestStatus';
import { RequestInfo } from '../../noteworthy-api/objects/RequestInfo';

interface RequestStatusListProps extends StackProps
{
    /** List of requests to display. */
    request_list: readonly RequestInfo[];

    /** Function called when a request status is clicked. 
     * Passed the clicked request's info 
     */
    on_request_click?: (info: RequestInfo) => void;
}

/**
 * List of RequestStatus components.
 */
class RequestStatusList extends React.Component<RequestStatusListProps> {
    render() {
        const {request_list, on_request_click, ...other} = this.props;
        if (request_list.length === 0) return <h3>No requests were found.</h3>
        return (
            <Stack {...other}>
                {request_list.map(
                    info => <RequestStatus 
                        request_info={info} 
                        on_click={on_request_click}
                        style={{borderColor: "black"}}/>
                )}
            </Stack>
        );
    }
}

export default RequestStatusList;