import React from 'react';
import { RequestInfo } from '../../noteworthy-api/objects/RequestInfo';
import { Button, Col, Container, ProgressBar, Row } from 'react-bootstrap';

interface RequestStatusProps extends React.HTMLAttributes<HTMLDivElement> {
    request_info: RequestInfo;
    on_click?: (request_info: RequestInfo) => void;
}

class RequestStatus extends React.Component<RequestStatusProps> {
    onClick() {
        if (this.props.on_click === undefined) return;
        return this.props.on_click(this.props.request_info);
    }

    render() {
        const {
            request_info,
            on_click,
            ...other
        } = this.props;

        const {
            submission_time, 
            review_time, 
            status_string,
            notes_reviewed,
            size
        } = request_info;
        return (
            <Container 
                as={Button} 
                variant="secondary"
                onClick={this.onClick.bind(this)}
                {...other}
            >
                <Row>
                    <Col style={{textAlign: "start"}}>
                        Submitted: {submission_time} EST
                    </Col>
                    <Col style={{textAlign: "end"}}>
                        {review_time && `Finished Review: ${review_time} EST`}
                    </Col>
                </Row>
                <Row>
                    <Col style={{textAlign: "start"}}>
                        Status: {status_string}
                    </Col>
                    <Col style={{textAlign: "end"}}>
                        Notes Submitted: {size}
                    </Col>
                </Row>
                {notes_reviewed &&
                    <ProgressBar
                        now={(notes_reviewed / size) * 100}
                        label={`${notes_reviewed} / ${size}`}
                        style={{marginTop: "10px"}}
                    />
                }
            </Container>
        );
    }
}

export default RequestStatus;