import { createRef, useEffect, useRef, useState } from "react"
import { Accordion, Button, Form, Spinner, Stack, Table } from "react-bootstrap";
import { getClient } from "../noteworthy-api/ClientFactory";
import { ICriteriaTable, ICriteriaRow } from "../noteworthy-api/NoteworthyApiClient";

interface CriteriaTableProps {
    on_selection?: (criteria: number[]) => void;
}

interface CriteriaTableRowProps {
    criteria: number;
    header: string;
    text: string;
    on_click?: (criteria: number) => void;
}

const CriteriaTableRow = ({ 
    criteria, 
    header, 
    text, 
    on_click 
}: CriteriaTableRowProps) => {
    const on_click_wrapper = () => {
        if (on_click) {
            on_click(criteria);
        }
    }

    return (
        <tr>
            <th>
                <Form.Check onClick={on_click_wrapper}/>
                {header}
            </th>
            <td>
                {text}
            </td>
        </tr>
    );
}

const CriteriaTable = ({ on_selection }: CriteriaTableProps) => {
    const [table, set_table] = useState<ICriteriaTable>({} as ICriteriaTable);
    const [loaded, set_loaded] = useState<boolean>(false);
    const selected_criteria = useRef<number[]>([]);

    useEffect(() => {
        const api = getClient();
        
        api.getCriteriaTable()
        .then(criteria_table => {
            set_table(criteria_table);
            set_loaded(true);
        });
    }, []);

    if (!loaded) return <Spinner/>;

    const toggle_criteria = (criteria: number) =>{
        const index = selected_criteria.current.indexOf(criteria);
        
        if (index > -1) {
            selected_criteria.current.splice(index, 1);
        } else {
            selected_criteria.current.push(criteria);
        }
    }

    const selection_wrapper = () => {
        if (on_selection) {
            on_selection(selected_criteria.current);
        }
    }

    return (
        <>
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>Prompt</th>
                    <th>Details</th>
                </tr>
            </thead>
            <tbody>
                {table.table.map(row => {
                    return <CriteriaTableRow 
                        criteria={row.criteria_number} 
                        header={row.title} 
                        text={row.prompt_text}
                        on_click={toggle_criteria}/>;
                })}
            </tbody>
        </Table>
        <Button variant="primary" onClick={selection_wrapper}>
            Done
        </Button>
        </>
    );
}

export default CriteriaTable;