import { NoteworthyApiClient } from "./NoteworthyApiClient";
import { ConcreteClient } from "./clients/ConcreteClient";
import { TestClient } from "./clients/TestClient";

let client: NoteworthyApiClient | null = null;

/**
 * Gets the client to use for interaction with Noteworthy.
 * @returns TestClient if running on localhost otherwise ConcreteClient.
 */
export function getClient(): NoteworthyApiClient {
    if (client === null) {
        if (window.location.hostname.includes("localhost")) {
            client = new TestClient();
        } else {
            client = new ConcreteClient();
        }
    }

    return client;
}