import { Note } from "../noteworthy-api/objects/Note";
import { NoteFileProcessor } from "./NoteFileProcessor";
import * as Papa from 'papaparse';

type RowType = {[key: string]: string};

export class NoteCsvProcessor implements NoteFileProcessor {
    static parsePromise(csv: File): Promise<Papa.ParseResult<RowType>> {
        return new Promise((complete, error) =>
            Papa.parse(csv, {header: true, complete, error})
        );
    }
    
    parse_result: Promise<Papa.ParseResult<RowType>>;

    constructor(csv: File) {
        if (!csv.name.toLowerCase().endsWith("csv")) 
            throw new Error("The given file is not a CSV.");
       
        this.parse_result = NoteCsvProcessor.parsePromise(csv);
    }

    async getHeaders(): Promise<string[]> {
        let parsed_csv = await this.parse_result;
        if (parsed_csv.meta.fields === undefined) 
            throw Error("Unkown parsing problem. CSV fields are undefined.");
        
        return parsed_csv.meta.fields;
    }

    async getNotes(
        id_header: string, 
        content_headers: string[], 
        metadata_headers: string[]
    ): Promise<Note[]> {
        let parsed_csv = await this.parse_result;
        
        let notes: Note[] = [];
        for (let row of parsed_csv.data) {
            let note_id = row[id_header];
            if (note_id.length === 0) {
                continue;
            }

            let note_text = content_headers.reduce(
                (acc, header) => acc + row[header] + " ",
                "");
            
            let metadata: RowType[] = [];
            for (let header of metadata_headers) {
                let curr_metadata: RowType = {};
                curr_metadata[header] = row[header];
                metadata.push(curr_metadata);
            }

            notes.push({
                note_id,
                note_text,
                metadata
            })
        }

        return notes;
    }

}