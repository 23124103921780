import React from 'react';
import { NoteFileProcessor } from '../../note-file-processor/NoteFileProcessor';
import { Note } from '../../noteworthy-api/objects/Note';
import { Button, Stack } from 'react-bootstrap';
import SelectItems from '../../components/SelectItems';


type NoteExtractionFormProps = {
    file_processor: NoteFileProcessor;
    on_submit?: (notes: Note[]) => void;
}

function firstOrDefault<T, K>(array: T[], default_value: K): T | K {
    if (array.length === 0) return default_value;
    return array[0];
}

const NoteExtractionForm = ({
        file_processor, 
        on_submit}: NoteExtractionFormProps) => {
    const [file_headers, set_file_headers] = React.useState<string[]>([]);
    const [id_header, set_id_header] = React.useState<string | null>(null);
    const [text_headers, set_text_headers] = React.useState<string[]>([]);
    const [
        metadata_headers, 
        set_metadata_headers
    ] = React.useState<string[]>([]);
    const [got_headers, set_got_headers] = React.useState<boolean>(false);
    const [submitted, set_submitted] = React.useState<boolean>(false);
    
    const on_submit_click = () => {
        set_submitted(true);
    }

    React.useEffect(() => {
        if (!got_headers) {
            file_processor.getHeaders()
                .then(headers => set_file_headers(headers))
                .then(() => set_got_headers(true));
        }
        
        if (submitted) {
            if (id_header === null || text_headers.length === 0) {
                set_submitted(false);
                throw new Error("Invalid headers given." 
                + " Check that ID header is set and there is at"
                + " least one text header.");
            }

            file_processor.getNotes(id_header, text_headers, metadata_headers)
            .then(on_submit);
        }
    }, [submitted, set_submitted, file_processor, set_file_headers, set_got_headers])

    if (!got_headers) return <h3>Processing file...</h3>
    return (
        <Stack gap={5}>
            <SelectItems 
                search_items={file_headers}
                on_change={headers => 
                    set_id_header(firstOrDefault(headers, null))}
                placeholder="Choose Header"
                header="ID Header"
                max_selected={1}/>
            <SelectItems 
                search_items={file_headers}
                on_change={headers => set_text_headers(headers)}
                placeholder="Choose Header"
                header="Text Headers"/>
            <SelectItems 
                search_items={file_headers}
                on_change={headers => set_metadata_headers(headers)}
                placeholder="Choose Header"
                header="Extra Info Headers"/>
            <Button 
                onClick={on_submit_click} 
                disabled={submitted}>Submit Notes</Button>
        </Stack>
    );
}

export default NoteExtractionForm;