import React from "react";
import UserAPI from "../UserAPI";
import { NotificationType, NotifierContext } from "./NotificationProvider";
import { Outlet, useOutletContext } from "react-router-dom";

export interface LoginHandler {
    (email: string, password: string): void;
}

/* LOGIN STATE INTERFACES */
export enum LoginStatus {
    LOGGED_OUT,
    PENDING_LOGIN,
    LOGGING_IN,
    LOGGED_IN
}

export interface LoginState {
    status: LoginStatus;
    user_id?: string;
}

/* CONTEXTS */
export const LoginContext = React.createContext({} as LoginState);
export const LoginHandlerContext = React.createContext({} as LoginHandler);

/** Provides the ability to log in a user and get the current user. */
const LoginManager = ({ children }: React.PropsWithChildren) => {
    const [login_state, set_login_state] = React.useState<LoginState>({ status: LoginStatus.LOGGED_OUT });
    const password_ref = React.useRef("");
    const login_client = React.useMemo(() => new UserAPI(), []);
    const notifier = React.useContext(NotifierContext);

    const login_handler = (email: string, password: string) => {
        password_ref.current = password;
        set_login_state({
                status: LoginStatus.PENDING_LOGIN,
                user_id: email,
            }
        );
    }

    React.useEffect(() => {
        if (login_state.status === LoginStatus.PENDING_LOGIN) {
            if (login_state.user_id === undefined) throw new Error("Bad login state.");
            
            login_client.login(login_state.user_id, password_ref.current)
            .then(success => {
                if (success) {
                    set_login_state({ 
                        status: LoginStatus.LOGGED_IN, 
                        user_id: login_state.user_id 
                    });
                } else {
                    notifier(
                        NotificationType.ERROR, 
                        "Couldn't log in. Check that your email and password are correct, then try again."
                    );

                    set_login_state({
                        status: LoginStatus.LOGGED_OUT
                    });
                }
            })
            .catch(e => {
                notifier(NotificationType.ERROR, e.message);
                set_login_state({status: LoginStatus.LOGGED_OUT});
            });

            set_login_state({ 
                status: LoginStatus.LOGGING_IN, 
                user_id: login_state.user_id 
            });
        }
    }, [login_state]);
    
    return (
        <LoginHandlerContext.Provider value={login_handler}>
            <LoginContext.Provider value={login_state}>
                {children}
            </LoginContext.Provider>
        </LoginHandlerContext.Provider>
    );
}

interface LoginOutletContext {
    login_state: LoginState;
    login_handler: LoginHandler;
}

/* Outlet and context for login to be passed through react-router */
export function useLoginOutletContext(): LoginOutletContext {
    return useOutletContext<LoginOutletContext>();
}

/* React router outlet which provides the LoginContext */
export const LoginProviderOutlet = () => {
    const login_context = React.useContext(LoginContext);
    const login_handler_context = React.useContext(LoginHandlerContext);
    return <Outlet context={{
        login_state: login_context,
        login_handler: login_handler_context
    }} />
}

export default LoginManager;