import { Review } from "../../noteworthy-api/objects/Review";

interface FeedbackListProps {
    reviews: Review[];
}

const FeedbackList = ({ reviews }: FeedbackListProps) => {
    return (
        <>
            <h3>Feedback</h3>
            {
                reviews.map(
                    review => {
                        return (
                            <ul>
                                <li>Note: {review.note_id}</li>
                                <ul>
                                    {review.ratings.map(
                                        rating => <li>Criteria {rating.criteria_number}: {rating.feedback}</li>
                                    )}
                                </ul>
                            </ul>
                        );
                    }
                )
            }
        </>
    );
}

export default FeedbackList;