import LoginManager, { LoginProviderOutlet } from './components/LoginManager';
import NoteworthyNavbar from './components/NoteworthyNavbar';
import NotificationProvider from './components/NotificationProvider';

function App() {
    return (
        <div className="App">
            <NoteworthyNavbar/>
            <NotificationProvider>
                <LoginManager>
                    <div style={{padding: "5px"}}>
                        <LoginProviderOutlet/>
                    </div>
                </LoginManager>
            </NotificationProvider>
        </div>
    );
}

export default App;