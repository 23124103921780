import RequestDisplay from './RequestDisplay';
import { Col, Container, Row } from 'react-bootstrap';
import NoteFileForm from './NoteFileForm';
import { useNavigate } from 'react-router-dom';
import RequestsManager from './RequestsManager';
import { LoginStatus, useLoginOutletContext } from '../../components/LoginManager';
import { useEffect } from 'react';
import PoweredByOpenai from '../../components/PoweredByOpenai';

/**
 * Page for displaying requests made by a user and
 * submission of new requests.
 */
const UserRequestsPage = () => {
    const {login_state, login_handler} = useLoginOutletContext();
    const navigate = useNavigate();

    useEffect(() => {
        if (login_state.status !== LoginStatus.LOGGED_IN)
            navigate("/");
    }, [])

    if (login_state.user_id === undefined) return <h3>User is undefined.</h3>;

    return (
        <>
        <RequestsManager user_id={login_state.user_id}>
            <Container fluid>
                <Row>
                    <Col>
                        <RequestDisplay 
                            on_request_click={request => 
                                navigate(`/reviews?request=${request.request_id}`)
                            }
                        />
                    </Col>
                    <Col>
                        <NoteFileForm user_id={login_state.user_id}/>
                    </Col>
                </Row>
            </Container>
        </RequestsManager>
        </>
    );
}

export default UserRequestsPage;