import React from 'react';
import Table from 'react-bootstrap/Table';

export type Row = {[key: string]: React.ReactNode}

interface DictionaryTableProps extends React.HTMLAttributes<HTMLTableElement>
{
    rows: Row[];
}

/**
 * React component which is a table created from a list of Row objects.
 */
class DictionaryTable extends React.Component<DictionaryTableProps> {
    /**
     * Gets the headers of the table.
     * @returns List of header string for the table.
     */
    getTableHeaders(): string[] {
        if (this.props.rows.length === 0) return [];
        return Object.keys(this.props.rows[0]);
    }

    /**
     * Gets the formatted header row for the table.
     * @returns The header row ReactNode.
     */
    getHeaderRow(): React.ReactNode {
        if (this.props.rows.length === 0) return <tr></tr>;
        
        return (
            <tr>
                {this.getTableHeaders().map(
                    (h, index) => <th key={index}>{h}</th>
                )}
            </tr>
        );
    }

    /**
     * Gets the values of a row based on the headers of the table.
     * @param row The row object to get the values of.
     * @returns The values for the row in order of the headers of the table.
     */
    getRowValues(row: Row): React.ReactNode[] {
        return Array.from(this.getTableHeaders(), header => row[header])
    }

    /**
     * Maps an array of value into a list of table cells with the given values
     * @param array The array to convert.
     * @returns A list of JSX <td> elements.
     */
    arrayToCells(array: React.ReactNode[]): React.ReactNode[] {
        return array.map(cell_val => <td>{cell_val}</td>);
    }

    /**
     * Gets the JSX formatted rows for the table.
     * @returns List of JSX <tr> elements.
     */
    getTableRows(): React.JSX.Element[] {
        return this.props.rows
            .map(this.getRowValues.bind(this))
            .map(row_values => <tr>{this.arrayToCells(row_values)}</tr>);
    }

    render() {
        return (
            <Table responsive {...this.props}>
                <thead>
                    {this.getHeaderRow()}
                </thead>
                <tbody>
                    {this.getTableRows()}
                </tbody>
            </Table>
        );
    }
}

export default DictionaryTable;