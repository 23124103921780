import React from 'react';
import NoteFileSubmission from './NoteFileSubmission';
import { NoteFileProcessor } from '../../note-file-processor/NoteFileProcessor';
import { RequestsContext, RequestsControllerContext, RequestsStatus, RequestsSubmitAction } from './RequestsManager';
import { Spinner } from 'react-bootstrap';
import NoteExtractionForm from './NoteExtractionForm';

interface NoteFileFormProps {
    user_id: string;
}

enum FormStage {
    FILE_SUBMIT,
    HEADER_CHOICE
}

const NoteFileForm = ({ user_id }: NoteFileFormProps) => {
    const requests_controller = React.useContext(RequestsControllerContext);
    const requests_state = React.useContext(RequestsContext);
    const [stage, set_stage] = React.useState(FormStage.FILE_SUBMIT);
    const file_processor = React.useRef<NoteFileProcessor>();

    if (requests_state.status === RequestsStatus.SUBMITTING) return <Spinner />

    switch (stage) {
        case FormStage.FILE_SUBMIT:
            return (
                <NoteFileSubmission on_submission={
                        fp => {
                            file_processor.current = fp;
                            set_stage(FormStage.HEADER_CHOICE);
                        }
                    }
                />
            );

        case FormStage.HEADER_CHOICE:
            if (file_processor.current === undefined) 
                throw new Error("File processor is undefined!");

            return (
                <NoteExtractionForm 
                    file_processor={file_processor.current}
                    on_submit={
                        notes => {
                            requests_controller(
                                {
                                    type: "submit", 
                                    notes
                                } as RequestsSubmitAction
                            );
                            
                            set_stage(FormStage.FILE_SUBMIT);
                        }
                    }
                />
            );
    }
}

export default NoteFileForm;