import { CriteriaRating, Review } from "../noteworthy-api/objects/Review";
import * as XLSX from 'xlsx';
import { ReviewFileWriter } from "./ReviewFileWriter";

type ReviewRow = { [header: string]: string };

const item_titles = [
    "Psychotherapy Intervention",
    "Intervention Elaboration",
    "Client Response",
    "Progress",
    "Plan"
]

const item1_rating = (criterias: CriteriaRating[]) => {
    if (criterias[0].rating && criterias[1].rating) 
        return "2";
    else if (criterias[0].rating)
        return "1"

    return "0"
}

const item2_rating = (criterias: CriteriaRating[]) => {
    if (criterias[2].rating && criterias[3].rating) 
        return "2";
    else if (criterias[2].rating)
        return "1"

    return "0"
}

const item3_rating = (criterias: CriteriaRating[]) => {
    if (criterias[4].rating || criterias[5].rating) 
        return "2";
    
    return "0"
}

const item4_rating = (criterias: CriteriaRating[]) => {
    if (criterias[6].rating && criterias[7].rating && criterias[8].rating) 
        return "2";
    else if (criterias[6].rating)
        return "1"

    return "0"
}

const item5_rating = (criterias: CriteriaRating[]) => {
    if (criterias[9].rating 
        && criterias[10].rating 
        && criterias[11].rating 
        && criterias[12].rating
    ) 
        return "2";
    else if (criterias[9].rating)
        return "1"

    return "0"
}

const item_criterias = [2, 2, 2, 3, 4]
const num_criteria = 13;

function flattenReview(reviews: Review[]): ReviewRow[]  {
    let result = [];

    for (const review of reviews) {
        if (review.ratings.length === 0) continue;
        
        let row: ReviewRow = {
            "Note ID": review.note_id
        };

        for (const criteria of review.ratings) {
            row["Criteria " + criteria.criteria_number] = criteria.rating.toString();
            
            if (criteria.feedback)
                row["Feedback " + criteria.criteria_number] = criteria.feedback;
        }

        row[item_titles[0]] = item1_rating(review.ratings);
        row[item_titles[1]] = item2_rating(review.ratings);
        row[item_titles[2]] = item3_rating(review.ratings);
        row[item_titles[3]] = item4_rating(review.ratings);
        row[item_titles[4]] = item5_rating(review.ratings);
    
        result.push(row);
    }

    return result;
}

export class ExcelReviewsWriter implements ReviewFileWriter {
    downloadReviews(reviews: Review[]) {
        const rows = flattenReview(reviews);

        const date = new Date();

        let workbook = XLSX.utils.book_new();
        let sheet = XLSX.utils.json_to_sheet(rows);
        XLSX.utils.book_append_sheet(workbook, sheet, "reviews");
        XLSX.writeFile(workbook, `reviews ${date.toString()}.xlsx`);
    }
}