import { Review } from "../../noteworthy-api/objects/Review";
import DictionaryTable from "./DictionaryTable";

interface ReviewDisplayProps {
    reviews: Review[];
}

function reviewsToRows(reviews: Review[]): any[] {
    let results: any[] = [];
    for (const review of reviews) {
        let review_row: any = { "Note ID": review.note_id };
        for (const rating of review.ratings) {
            review_row["Criteria " + rating.criteria_number] = rating.rating;
        }
        results.push(review_row);
    }
    
    return results;
}

const ReviewDisplay = ({ reviews }: ReviewDisplayProps) => {
    return <DictionaryTable rows={reviewsToRows(reviews)}/>;
}

export default ReviewDisplay;