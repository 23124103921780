import React from "react";
import { RequestInfo } from "../../noteworthy-api/objects/RequestInfo"
import { Note } from "../../noteworthy-api/objects/Note";
import { getClient } from "../../noteworthy-api/ClientFactory";
import { NotifierContext, NotificationType } from "../../components/NotificationProvider";

/* REQUESTS MANAGER PROPS */
interface RequestsManagerProps extends React.PropsWithChildren {
    user_id: string;
}

/* REQUESTS ACTIONS INTERFACES */
export interface RequestsAction {
    type: string;
}

export interface RequestsSubmitAction extends RequestsAction {
    type: "submit";
    notes: Note[];
    user_id: string;
}

export interface RequestsRefreshAction extends RequestsAction {
    type: "refresh";
    user_id: string;
}

export interface RequestsUpdateAction extends RequestsAction {
    type: "update";
    requests: RequestInfo[];
}

export interface RequestsController {
    (action: RequestsAction): boolean;
}

/* REQUESTS STATE INTERFACES */
export enum RequestsStatus {
    IDLE,
    REFRESHING,
    SUBMITTING,
    PENDING_SUBMIT,
    PENDING_REFRESH,
    PENDING_DELETE
}

export interface RequestsState {
    status: RequestsStatus;
    requests: RequestInfo[];
}

interface PendingSubmitState extends RequestsState{
    status: RequestsStatus.PENDING_SUBMIT;
    requests: RequestInfo[];
    notes: Note[];
}

/* PROPS */
interface RequestsManagerProps {
    user_id: string;
    children?: React.ReactNode;
}

/* CONTEXTS */
export const RequestsContext = React.createContext({} as RequestsState);
export const RequestsControllerContext = React.createContext({} as RequestsController);

/* ACTION REDUCER */
function requestManagerReducer(
    state: RequestsState, 
    action: RequestsAction
): RequestsState {
    switch (action.type) {
        case "submit":
            const submit_action = action as RequestsSubmitAction;
            return {
                status: RequestsStatus.PENDING_SUBMIT,
                requests: state.requests,
                notes: submit_action.notes
            } as PendingSubmitState;

        case "submitting":
            return {
                status: RequestsStatus.SUBMITTING,
                requests: state.requests
            };

        case "refresh":
            const refresh_action = action as RequestsRefreshAction;
            return {
                status: RequestsStatus.PENDING_REFRESH,
                requests: state.requests
            };
        
        case "refreshing":
            return {
                status: RequestsStatus.REFRESHING,
                requests: []
            };

        case "update":
            const update_action = action as RequestsUpdateAction;
            return {
                status: RequestsStatus.IDLE,
                requests: update_action.requests
            };

        default:
            throw Error("Invalid action given through requests dispatcher.")
    }
}

/**
 * Provides control over the request list to child components.
 */
const RequestsManager = ({ user_id, children }: RequestsManagerProps) => {
    const [requests_state, dispatcher] = React.useReducer(
        requestManagerReducer,
        {
            status: RequestsStatus.PENDING_REFRESH,
            requests: []
        }
    );
    const noteworthy_client = React.useMemo(getClient, []);
    const notifier = React.useContext(NotifierContext);

    const error_handler = (e: Error) => {
        notifier(NotificationType.ERROR, e.message);
        dispatcher({ type: "refresh" });
    }

    // If in the future there should be restrictions on actions, that can be changed
    // here.
    const controller = React.useCallback((action: RequestsAction) => {
        dispatcher(action);
        return true;
    }, [requests_state, dispatcher]);

    React.useEffect(() => {
        if (requests_state.status === RequestsStatus.PENDING_SUBMIT) {
            const submit_state = requests_state as PendingSubmitState;
            
            dispatcher({ type: "submitting" });

            // Submit notes, then refresh and notify.
            noteworthy_client.submitNotes(user_id, submit_state.notes)
            .then(() => dispatcher({ type: "refresh" }))
            .then(() => notifier(
                    NotificationType.SUCCESS, "Submitted your notes for review!"
                )
            )
            .catch(error_handler);
        }

        if (requests_state.status === RequestsStatus.PENDING_REFRESH) {
            dispatcher({ type: "refreshing" });

            noteworthy_client.getUserRequests(user_id)
            .then(requests => dispatcher(
                    { type: "update", requests } as RequestsUpdateAction
                )
            )
            .catch(error_handler);
        }
    }, [requests_state]);

    return (
        <RequestsContext.Provider value={requests_state}>
            <RequestsControllerContext.Provider value={controller}>
                {children}
            </RequestsControllerContext.Provider>
        </RequestsContext.Provider>
    );
}

export default RequestsManager;