import React from "react";
import { RequestInfo } from "../../noteworthy-api/objects/RequestInfo";
import RequestStatusList from "./RequestStatusList";
import { Button, Spinner, Stack } from "react-bootstrap";
import { RequestsContext, RequestsControllerContext, RequestsStatus } from "./RequestsManager";

type RequestDisplayProps = {
    on_request_click?: (request: RequestInfo) => void;
}

const RequestDisplay = ({ on_request_click }: RequestDisplayProps) => {
    const requests_controller = React.useContext(RequestsControllerContext);
    const request_state = React.useContext(RequestsContext);

    return (
        <Stack gap={1}>
            <Button
                variant="primary"
                onClick={() => requests_controller({ type: "refresh" })}
                disabled={request_state.status !== RequestsStatus.IDLE}
            >Refresh Requests</Button>
            <div style={{
                borderStyle:"inset",
                borderColor: "black", 
                height: "80vh", 
                overflowY: "auto",
                display: "flex",
                justifyContent: "center"
            }}>
                {request_state.status === RequestsStatus.REFRESHING
                    ? <Spinner/>
                    : <RequestStatusList
                        request_list={request_state.requests}
                        on_request_click={on_request_click}
                    />
                }
            </div>
        </Stack>
    );
}

export default RequestDisplay;